import styled from '@emotion/styled';
import Logo from 'assets/grupo_safra_logo.svg';

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const LoadingFullScreen = () => {
  return (
    <AlignCenter>
      <img src={Logo} alt="loading..." width="300px" />
    </AlignCenter>
  );
};
export default LoadingFullScreen;
